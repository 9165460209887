import React from 'react'
import Spinner from 'react-spinner-material'

export default class Loading extends React.Component{

    render(){
        return(
            <div className="fallback-loading">
				<Spinner radius={80} color={'#073150'} stroke={8} visible={true} />
			</div>
        )
    }
}