import React from 'react'

// ================= LIBRARIES ================= //
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import { isAuthenticated } from './services/authentication'
// ================= LIBRARIES ================= //

// ================= STYLES ================= //
import Loading from './components/loading'
// ================= STYLES ================= //

// ================= STYLES ================= //
import './assets/styles/global.css'
import './assets/styles/style.min.css'
import 'flickity/dist/flickity.min.css'
// import 'sweetalert2/src/sweetalert2.scss'

// ================= STYLES ================= //


// ================= PAGES ================= //
	const Login = React.lazy(() => import('./pages/public/login'))
	const RecuperarSenha = React.lazy(() => import('./pages/public/recuperar_senha'))
	const Cadastro = React.lazy(() => import('./pages/public/cadastro'))
	const Ativacao = React.lazy(() => import('./pages/public/ativacao'))
	const RedefinirSenha = React.lazy(() => import('./pages/public/redefinir_senha'))
	const Layout = React.lazy(() => import('./pages/private/layout'))
// ================= PAGES ================= //


// ================= PRIVATE-ROUTE ================= //
	const PrivateRoute = ({ component: Component, ...rest }) => (
		<Route
			{...rest}
			render={props =>isAuthenticated() ? (
				<Component {...props} />
			) : (
				<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
			)
		}
		/>
	)
// ================= PRIVATE-ROUTE ================= //



export default class App extends React.Component{

	render(){
		return(
			<BrowserRouter>
				<React.Suspense fallback={<Loading />}>
					<Switch>
						<Route exact path="/login" render={props => <Login {...props}/>} />
						<Route exact path="/recuperar-senha" render={props => <RecuperarSenha {...props}/>} />
						<Route exact path="/cadastro" render={props => <Cadastro {...props}/>} />
						<Route exact path="/ativacao" render={props => <Ativacao {...props}/>} />
						<Route exact path="/redefinir_senha" render={props => <RedefinirSenha {...props}/>} />

						<PrivateRoute path="/" component={props => <Layout {...props}/>} />
					</Switch>
				</React.Suspense>
			</BrowserRouter>
		)
	}
}