
export const isAuthenticated  = () => localStorage.getItem('@ValorizaENG-Token') !== null

export const getToken = () => localStorage.getItem('@ValorizaENG-Token')

export const setToken = token => {
    localStorage.setItem('@ValorizaENG-Token', token)
}

export const deleteToken = () => {
    localStorage.removeItem('@ValorizaENG-Token')
}

